import { FeatureFlagRenderer } from './utils/FeatureFlagRenderer';
import { ConfigurationClient } from './utils/ConfigurationClient';

window.onload = async () => {
  // Init configuration client
  const client = new ConfigurationClient();
  await client.init();

  // Run the feature flagging checkups
  const featureFlagRenderer = new FeatureFlagRenderer(client);
  featureFlagRenderer.displayFeatureFlaggedContent();

  // Mark the primary navigation as loaded once everything is done
  const mainNavigation = document.querySelectorAll('.md-nav--primary');
  mainNavigation.forEach((mn) => mn.classList.add('navigation-loaded'));

  // Mark the secondary navigation as loaded once everything is done
  const secondaryNavigation = document.querySelectorAll('.md-nav--secondary');
  secondaryNavigation.forEach((sn) => sn.classList.add('navigation-loaded'));
};
