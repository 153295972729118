import { AuthClient } from './AuthClient';

/**
 * Custom "feature flag" for content that should only be visible to viewers
 * with an ongoing valid AQtive Guard session.
 */
const AUTH_USER_FLAG = 'AuthenticatedUser';

export class ConfigurationClient {
  constructor() {
    this.presetFlags = {};
    this.defaultFlags = {
      // Uncomment the next line to simulate an authenticated session.
      // [AUTH_USER_FLAG]: true,
      // See available values in LaunchDarkly or `cs/web_findings/app/models/feature_flags.py`:
      //
      // For example, for the following flag definition...
      //    FEATURE_FLAG_XYZ = "FeatureFlagXyz"
      // ... add the following line
      //    FeatureFlagXyz: true
      //
      // *Do not commit* changes to this file.
    };
  }

  getFeatureFlag(flag) {
    if (flag in this.defaultFlags) {
      return this.defaultFlags[flag];
    }
    if (flag in this.presetFlags) {
      return this.presetFlags[flag];
    }
    return false;
  }

  async init() {
    await Promise.all([this.loadAuth(), this.loadFeatureFlags()]);
  }

  async loadFeatureFlags() {
    try {
      const apiUrl = `/api/internal/presets`;
      const response = await fetch(apiUrl);
      const data = await response.json();

      this.presetFlags = { ...this.presetFlags, ...data.featureFlags };
    } catch (e) {
      // do nothing, leave the environment as it is
    }
  }

  async loadAuth() {
    try {
      const authenticated = this.defaultFlags[AUTH_USER_FLAG] ? true : await new AuthClient().init();

      this.presetFlags = { ...this.presetFlags, [AUTH_USER_FLAG]: authenticated };
    } catch (e) {
      // Do nothing, if the page requires auth there will be a redirection, otherwise
      // assume the user is not authenticated
    }
  }
}
