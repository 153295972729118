export class AuthClient {
  async init() {
    try {
      const token = this.validateToken();
      const response = await fetch('/api/v2', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'API-KEY': token },
        body: JSON.stringify({ query: 'query { viewer { __typename } }' }),
      });
      await response.json();
      return true;
    } catch (e) {
      // Assume the user is not authenticated
      return false;
    }
  }

  validateToken() {
    const rawToken = localStorage.getItem('token');
    if (!rawToken) {
      throw new Error('Missing access token');
    }
    const jsonToken = JSON.parse(rawToken);
    if (!('value' in jsonToken && 'timeToLive' in jsonToken)) {
      throw new Error('Malformed access token');
    }
    const token = jsonToken.value;
    const ttl = jsonToken.timeToLive;
    if (Date.now() / 1000 > ttl) {
      throw new Error('Expired access token');
    }
    return token;
  }
}
