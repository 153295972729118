export class FeatureFlagRenderer {
  static FEATURE_FLAG_DATA_KEY = 'ff-with';
  static FEATURE_FLAG_DATASET_KEY = 'ffWith';
  static FEATURE_FLAG_HIDE_CLOSEST_DATASET_KEY = 'ffHideClosest';
  static FEATURE_FLAG_VALUE_DATASET_KEY = 'ffValue';

  static NAVIGATION_FEATURE_FLAGS_KEY = 'flags';
  static NAVIGATION_FEATURE_FLAG_ELEMENT_SELECTOR = 'ff';
  static NAVIGATION_FEATURE_FLAG_VALUE_DATASET_KEY = 'ffValue';

  static FORCE_HIDDEN_CLASS = 'force-hidden';

  constructor(client) {
    this.client = client;
  }

  /**
   * Iterate through elements that have feature flag wrapper.
   * If they match any of the provided flags, we will display the DOM element.
   */
  displayFeatureFlaggedContent() {
    this.displayFlaggedPage();
    this.displayNavigationContent();
    this.displayFlaggedContent();
  }

  displayFlaggedPage() {
    const overlay = document.getElementById('saq-ff-overlay');
    const meta = document.querySelector('meta[name="flags"]');

    // Page is not flagged, do nothing
    if (!meta) return;

    const metaFlags = meta.getAttribute('flags').split(',');
    const metaFlagValues = meta.getAttribute('flagValues');

    // By default, match the flag against "true"
    let values = Array(metaFlags.length).fill(true);
    if (metaFlagValues) {
      values = metaFlagValues.split(',').map((v) => {
        // Convert string booleans to booleans, leave custom values untouched:
        // "True" instead of "true" and "False" instead of "false" are necessary
        // to handle mkdocs bool-to-string conversion
        if (v === 'True') return true;
        if (v === 'False') return false;
        return v;
      });
    }

    // Match all flags
    let hasAllFlags = true;
    for (let i = 0; i < metaFlags.length && hasAllFlags; i++) {
      hasAllFlags = hasAllFlags && values[i] === this.client.getFeatureFlag(metaFlags[i]);
    }

    if (hasAllFlags) {
      meta.remove();
      overlay && overlay.remove();
    } else {
      window.location.replace(meta.getAttribute('redirectTo'));
    }
  }

  displayNavigationContent() {
    const nodes = document.querySelectorAll(FeatureFlagRenderer.NAVIGATION_FEATURE_FLAG_ELEMENT_SELECTOR);
    for (const node of nodes) {
      try {
        // Extract feature flag list into a string array. We allow comma (,) as a separator between different flags.
        const nodeFlags = node.attributes[FeatureFlagRenderer.NAVIGATION_FEATURE_FLAGS_KEY].value.split(',').map((ff) => ff.trim());

        // Enable data flag `data-ff-value` if we want to display a section depending on different values from boolean true.
        let targetValue = true;
        const datasetValue = node.dataset[FeatureFlagRenderer.NAVIGATION_FEATURE_FLAG_VALUE_DATASET_KEY];
        if (datasetValue !== undefined) {
          targetValue = datasetValue;
          if (['true', 'false'].includes(datasetValue)) {
            targetValue = datasetValue === 'true';
          }
        }

        // Check if all flags match the provided values.
        const hasAllFlags = nodeFlags.reduce((foundIt, ff) => foundIt && this.client.getFeatureFlag(ff) === targetValue, true);

        // Target the closest navigation item with a provided class
        const closestNodeToHide = node.closest('.md-nav__item');
        if (closestNodeToHide && !hasAllFlags) {
          closestNodeToHide.remove();
        } else {
          node.setAttribute('aria-hidden', 'false');
        }
      } catch (e) {
        // do nothing, we just leave the element hidden
      }
    }
  }

  displayFlaggedContent() {
    const nodes = document.querySelectorAll(`[data-${FeatureFlagRenderer.FEATURE_FLAG_DATA_KEY}]`);
    for (const node of nodes) {
      try {
        // Extract feature flag list into a string array. We allow comma (,) as a separator between different flags.
        const nodeFlags = node.dataset[FeatureFlagRenderer.FEATURE_FLAG_DATASET_KEY].split(',').map((ff) => ff.trim());

        // Enable data flag `data-ff-value` if we want to display a section depending on different values from boolean true.
        let targetValue = true;
        const datasetValue = node.dataset[FeatureFlagRenderer.FEATURE_FLAG_VALUE_DATASET_KEY];
        if (datasetValue !== undefined) {
          targetValue = datasetValue;
          if (['true', 'false'].includes(datasetValue)) {
            targetValue = datasetValue === 'true';
          }
        }

        // Check if all flags match the provided values.
        const hasAllFlags = nodeFlags.reduce((foundIt, ff) => foundIt && this.client.getFeatureFlag(ff) === targetValue, true);

        // If feature flags don't match, we have to hide an element.
        // In case where we want to hide some parent structure, we use the "hideClosest" value.
        if (!hasAllFlags && node.dataset[FeatureFlagRenderer.FEATURE_FLAG_HIDE_CLOSEST_DATASET_KEY]) {
          const closestNodeToHide = node.closest(node.dataset[FeatureFlagRenderer.FEATURE_FLAG_HIDE_CLOSEST_DATASET_KEY]);

          // We only hide if there is a match with the selector provided.
          // This is done on purpose to simplify this feature as a whole.
          if (closestNodeToHide) {
            closestNodeToHide.remove();
          }
          continue;
        }

        if (!hasAllFlags) {
          node.remove();
        } else {
          node.setAttribute('aria-hidden', 'false');
        }
      } catch (e) {
        // do nothing, we just leave the element hidden
      }
    }
  }
}
